// import Errors from '../components/errors'
import Page from "../templates/page";
import { graphql } from "gatsby";
import React from "react";


export const query = graphql`
  query FrontpageQuery {
    page: sanityPage(id: {eq: "e07ca5b6-bcc6-5d90-8489-39702cd11394"}) {
      id
      _id
      _type
      hero {
        _type
        subtitle {
          _key
          _type
          en
          es
        }
        illustration {
          image {
            asset {
              _id
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
            alt
          }
        }
        ctaHero {
          title {
            en
            es
            _type
          }
          link {
            en
            es
            _type
          }
          kind
          route {
            es
            en
            _type
          }
        }
        title {
          es
          en
          _type
        }
      }
      content {
        ... on SanityInstagramPosts {
          _key
          _type
          title {
            es
            en
            _type
          }
        }
        ... on SanityTextBlockEmbed {
          _type
          _rawBody(resolveReferences: {maxDepth: 10})

          title {
            es
            en
            _type
          }
        }
        ... on SanityTestimonial {
          _type
          url
          quote {
            _type
            en
            es
          }
          name
          image {
            alt
            asset {
              _id
            }
          }
        }
        ... on SanityReferenceGrid {
          _type
          cols
          title {
            _type
            en
            es
          }

          previewItem {
            ... on SanityService {
              id
              _type
              slug {
                en {
                  current
                }
                _type
                es {
                  current
                }
              }
              mainImage {
                alt
                asset {
                  _id
                  fluid(maxWidth: 300) {
                    ...GatsbySanityImageFluid
                  }
                  #fixed(width: 250, height: 150) {
                  #  ...GatsbySanityImageFixed
                  #}
                }
                crop {
                  _key
                  _type
                  top
                  bottom
                  left
                  right
                }
                hotspot {
                  _key
                  _type
                  x
                  y
                  height
                  width
                }
              }
              title {
                es
                en
                _type
              }
            }
            ... on SanityPortfolio {
              id
              _type

              title {
                es
                en
                _type
              }
              slug {
                es {
                  current
                }
                en {
                  current
                }
                _type
              }
              mainImage {
                alt
                asset {
                  _id
                  fluid(maxWidth: 700) {
                    ...GatsbySanityImageFluid
                  }
                }
                crop {
                  _key
                  _type
                  top
                  bottom
                  left
                  right
                }
                hotspot {
                  _key
                  _type
                  x
                  y
                  height
                  width
                }
              }
            }
          }
        }
        ... on SanityImageTextBlock {
          _type
          # variant
          position
          title {
            es
            en
            _type
          }
          mainImage {
            alt
            asset {
              _id
              fluid(maxWidth: 300) {
                ...GatsbySanityImageFluid
              }
            }
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
          }
          _rawBody(resolveReferences: {maxDepth: 10})
        }
        ... on SanityHero {
          _type
          subtitle {
            _key
            _type
            en
            es
          }
          illustration {
            image {
              asset {
                _id
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
              alt
            }
          }
          ctaHero {
            title {
              en
              es
              _type
            }
            link {
              en
              es
              _type
            }
            kind
            route {
              es
              en
              _type
            }
          }
          title {
            es
            en
            _type
          }
        }
        ... on SanityPreviewGrid {
          cols
          _type
          previewType
          title {
            en
            es
            _type
          }
          previewItem {
            title {
              en
              es
              _type
            }
            subtitle {
              en
              es
              _type
            }
            slug {
              es {
                current
              }
              en {
                current
              }
              _type
            }
            mainImage {
              # alt
              asset {
                _id
                altText
                fluid(maxWidth: 300) {
                  ...GatsbySanityImageFluid
                }
              }
              crop {
                _key
                _type
                top
                bottom
                left
                right
              }
              hotspot {
                _key
                _type
                x
                y
                height
                width
              }
            }
          }
        }
      }
      title {
        es
        en
        _type
      }
      slug {
        es {
          current
        }
        en {
          current
        }
        _type
      }
      openGraph {
        title
        description
        image {
          alt
          asset {
            _id
          }
        }
      }
    }

    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
      openGraph {
        title
        description
        #image {
        # ...SanityImage
        #}
      }
    }
  }
`

const IndexPage = (props) => {
  const {data, errors} = props
  // console.log(data)
  const page = data && data.page

  // if (errors) {
  //   return <Errors errors={errors} />
  // }

  return <Page {...props} />
}

export default IndexPage